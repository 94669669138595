body,
html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #031b4e;
  text-align: left;
}

.default-avatar {
  width: 30px;
  height: 30px;
  background-color: #3660ad;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.card {
  border-radius: 7px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: #fff !important;
  background-clip: border-box;
  border-radius: 5px;
  border: 1px solid #e0edff !important;
  margin-bottom: 1.3rem;
  box-shadow: -8px 12px 18px 0 #dadee8;
}
.card.sales-card {
  border: none;
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-primary-gradient {
  background-image: linear-gradient(
    to left,
    #0db2de 0%,
    #005bea 100%
  ) !important;
}
.bg-danger-gradient {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}
.bg-success-gradient {
  background-image: linear-gradient(
    to left,
    #48d6a8 0%,
    #029666 100%
  ) !important;
}
.bg-warning-gradient {
  background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}
.bg-transparent {
  background: transparent;
}

.tx-13 {
  font-size: 13px;
}
.tx-18 {
  font-size: 18px;
}

.bd-b-0 {
  border-bottom: 0 !important;
}

.total-revenue {
  display: flex;
  padding: 0 10px 20px;
  flex: none;
  position: absolute;
  z-index: 9;
}

.total-revenue > div + div {
  margin-left: 20px;
  padding-left: 20px;
}

.total-revenue h4 {
  font-size: 21px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  letter-spacing: -0.5px;
  margin-bottom: 3px;
}

.total-revenue label {
  font-size: 0.875rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.total-revenue label span {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 5px;
  border-radius: 100%;
}

.sales-bar {
  min-height: 180px !important;
  position: relative;
  bottom: -21px;
}

.input-no-spinners::-webkit-inner-spin-button,
.input-no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-no-spinners {
  -moz-appearance: textfield;
}

.autocomplete {
  position: relative;
}
.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}
.autocomplete-items div {
  padding: 6px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

@media (max-width: 500px) {
  .table-responsive table {
    font-size: 14px;
  }
}
